import React, { useState } from "react";
import { Button, Callout, Intent } from "@blueprintjs/core";
import { Invitation, Emerald, User } from "@prisma/client";

import styles from "./InvitationCallout.module.scss";

type PopulatedInvitation = Invitation & { emerald: Emerald; invitingUser: User };

type Props = {
	invitation: PopulatedInvitation;
	memberships?: any;
	setMemberships: any;
};

const InvitationCallout: React.FC<Props> = function ({
	invitation,
	memberships = [],
	setMemberships,
}) {
	const [acceptLoading, setAcceptLoading] = useState(false);
	const [declineLoading, setDeclineLoading] = useState(false);
	const [responded, setResponded] = useState(false);
	const handleAccept = async () => {
		setAcceptLoading(true);
		const response = await fetch("/api/invitationResponse", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				invitationId: invitation.id,
				invitationAccepted: true,
			}),
		});
		const newMembership = await response.json();
		setAcceptLoading(false);
		setResponded(true);
		setMemberships([...memberships, newMembership]);
	};
	const handleDecline = async () => {
		setDeclineLoading(true);
		await fetch("/api/invitationResponse", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				invitationId: invitation.id,
				invitationAccepted: false,
			}),
		});
		setDeclineLoading(false);
		setResponded(true);
	};
	if (responded) {
		return null;
	}
	return (
		<Callout className={styles.callout}>
			<div className={styles.text}>
				You've been invited by{" "}
				<a href={`/u/${invitation.invitingUser.slug}`}>{invitation.invitingUser.name}</a> to{" "}
				{invitation.permissions === "comment" ? "support" : "collaborate on"}{" "}
				<a href={`/e/${invitation.emerald.slug}`}>{invitation.emerald.title}</a>.
			</div>
			<div className={styles.invitationButtons}>
				<Button
					small
					onClick={handleDecline}
					loading={declineLoading}
					disabled={acceptLoading}
				>
					Decline
				</Button>
				<Button
					small
					onClick={handleAccept}
					loading={acceptLoading}
					disabled={declineLoading}
					intent={Intent.SUCCESS}
				>
					Accept
				</Button>
			</div>
		</Callout>
	);
};

export default InvitationCallout;
