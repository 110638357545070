import React from "react";
import { NonIdealState } from "@blueprintjs/core";

import styles from "./EmptyState.module.scss";

type Props = {
	title?: string;
	icon?: any;
	description?: string;
	action?: any;
};

const EmptyState: React.FC<Props> = function (props) {
	return <NonIdealState className={styles.block} {...props} />;
};

export default EmptyState;
