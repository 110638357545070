import React, { useState } from "react";
import { InputGroup, Button, Intent } from "@blueprintjs/core";
// import { Tick } from "@blueprintjs/icons";

import styles from "./NewEmerald.module.scss";
import { useLoginContext } from "utils/client/hooks";
import { ReleaseNumber } from "components";

const NewEmerald: React.FC<{}> = () => {
	const loginData = useLoginContext();
	const [text, setText] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const postNewItem = async (evt: any) => {
		evt.preventDefault();
		if (!loginData) {
			return;
		}
		setIsLoading(true);
		const response = await fetch("/api/emerald", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				title: text,
			}),
		});
		const newItem = await response.json();
		window.location.href = `/e/${newItem.data.slug}`;
	};
	return (
		<div>
			<ReleaseNumber number={"_._"} />

			<form onSubmit={postNewItem} className={styles.form}>
				<InputGroup
					className={styles.input}
					placeholder="Create a new Emerald"
					value={text}
					onChange={(evt) => {
						setText(evt.target.value);
					}}
				/>
				{text && (
					<Button
						className={styles.newButton}
						icon={"tick"}
						small
						minimal
						intent={Intent.SUCCESS}
						type="submit"
						loading={isLoading}
					/>
				)}
			</form>
		</div>
	);
};

export default NewEmerald;
