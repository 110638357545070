import React from "react";
import classNames from "classnames";

import { isMajorVersion } from "utils/shared/versions";

import styles from "./ReleaseNumber.module.scss";

type Props = {
	number: string;
	large?: boolean;
	unread?: boolean;
	className?: string;
	highlightMajor?: boolean;
};

const ReleaseNumber: React.FC<Props> = function ({
	number,
	large = false,
	unread = false,
	highlightMajor = false,
	className = "",
}) {
	return (
		<div
			id={`v${number}`}
			className={classNames(
				styles.wrapper,
				className,
				large && styles.large,
				unread && styles.unread,
				highlightMajor && isMajorVersion(number) && styles.major
			)}
		>
			{number === "_._" ? "New" : number}
		</div>
	);
};

export default ReleaseNumber;
