import React from "react";
import { Emerald, Member } from "@prisma/client";

import { useLoginContext } from "utils/client/hooks";
import { ReleaseNumber, EmeraldTitle } from "components";

import styles from "./EmeraldList.module.scss";
import { getLargerVersion } from "utils/shared/versions";

type PartialRelease = { id: string; version: string };
type PopulatedEmerald = Emerald & { members: Member[]; releases: PartialRelease[] };

type Props = {
	emeralds: PopulatedEmerald[];
};

const EmeraldList: React.FC<Props> = ({ emeralds }) => {
	const loginData = useLoginContext();
	return (
		<div className={styles.itemList}>
			<div className={styles.list}>
				{emeralds
					.sort((foo, bar) => {
						if (foo.createdAt < bar.createdAt) {
							return 1;
						}
						if (foo.createdAt > bar.createdAt) {
							return -1;
						}
						return 0;
					})
					.map((emerald) => {
						const latestVersion = emerald.releases.length
							? emerald.releases[0].version
							: "0.0";
						const members = emerald.members || [];
						const currentUserStatusVersion = members.reduce((prev, curr): string => {
							if (curr.version && curr.userId === loginData?.id) {
								return curr.version;
							}
							return prev;
						}, "0.0");
						// const isOwnedItem = item.userId === loginData?.id;
						const isUnread =
							latestVersion &&
							// !isOwnedItem &&
							getLargerVersion(currentUserStatusVersion, latestVersion) !==
								currentUserStatusVersion;
						return (
							<div key={emerald.id} className={styles.itemWrapper}>
								<ReleaseNumber number={latestVersion} unread={!!isUnread} />
								<EmeraldTitle
									// ownerSlug={item.user.slug}
									slug={emerald.slug}
									title={emerald.title}
									isPublic={emerald.isPublic}
									// showPrefix={showUserPrefix}
								/>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default EmeraldList;
